<template>
  <v-card flat class="mb-7 w-100">
    <v-card-text class="pa-5">
      <div class="d-md-flex align-start">
        <div class="d-flex flex-column ml-3">
          <h3 class="title font-weight-regular">Códigos inscritos</h3>
          <h5>Datos de códigos inscritor</h5>
        </div>
        <v-spacer></v-spacer>
        <div class="ml-auto d-flex justify-end">
        </div>
      </div>
      <v-data-table
        :headers="headerArray"
        :items="codes"
        hide-default-footer
        :page.sync="page"
        @page-count="pageCount = $event"
        :items-per-page="10"
      >
        <template v-slot:item.activo="{ item }">
          <div class="d-flex justify-end">
            <v-switch v-model="item.activo" @click="changeStateOfCode(item)" class="pa-0"/>
          </div>
        </template>
        <template v-slot:item.codigo="{ item }">
          <div class="d-flex" style="cursor: pointer;"
            @click="$router.push({ name: 'CodeDetail', params: { id: item.id } })"
          >
            <!-- <img
              src="@/assets/images/users/codedefault.svg"
              alt="user"
              class="rounded-circle"
              width="36"
            /> -->
            <div class="my-auto">
              <h4 class="font-weight-medium text-no-wrap">
                {{ item.codigo }}
              </h4>
            </div>
          </div>
        </template>
      </v-data-table>
      <v-pagination
          v-model="page"
          :length="pageCount"
      ></v-pagination>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios'
import { mapState, mapActions } from 'vuex'

export default {
  name: "TheCodeDataTable",
  data: () => ({
    page: 1,
    pageCount: 0,
    switch1: true,
    select: { state: "Febrero", abbr: "FL" },
    items: [
      { state: "Enero", abbr: "FL" },
      { state: "Febrero", abbr: "GA" },
      { state: "Marzo", abbr: "NE" },
      { state: "Abril", abbr: "CA" },
    ],
    headerArray: [
      { text: 'Código', sortable: true, value: 'codigo', align: 'start' },
      { text: 'Matriculas concretadas', value: 'cantidad_matriculas', sortable: true, align: 'start' },
      { text: 'Formularios llenados', value: 'cantidad_matriculas', sortable: true, align: 'start' },
      { text: 'Descuento', value: 'porcentaje_descuento', sortable: true, align: 'start' },
      { text: 'Activo', value: 'activo', sortable: true, align: 'end' }
    ]
  }),
  computed: {
    ...mapState(['codes']),
  },
  methods: {
    ...mapActions(['fetchCodes']),
    async changeStateOfCode (item) {
      await axios.patch(`api/admin/matriculas/codigos_referencia/${item.id}/`,
        {
          activo: item.activo
        }
      )
    }
  },
  watch: {
    '$store.state.reload': function() {
      this.fetchCodes()
    }
  },
  async created () {
    await this.fetchCodes()
  }
};
</script>
